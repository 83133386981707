<template>
  <el-dialog
    title="Fechamento do romaneio"
    v-model="shouldShow"
    width="70%"
    lock-scroll
    append-to-body
  >
    <el-row type="flex" justify="start"
      ><h5 class="mb-0">Dados do romaneio</h5></el-row
    >
    <el-descriptions class="margin-top" :column="4" size="small" border>
      <el-descriptions-item>
        <template #label> Produtor </template>
        {{ provider?.name || "desconhecido" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Aferido </template>
        {{ CarcassesWeight?.toFixed(2)?.replace(".", ",") }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Descrito </template>
        {{ AnimalsWeight?.toFixed(2)?.replace(".", ",") }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Carcaças </template>
        {{
          packing?.animals?.reduce((t, a) => (t += a?.carcasses?.length), 0) ||
          0
        }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="3" size="small" border>
      <el-descriptions-item>
        <template #label> Preços </template>
        {{
          provider?.prices
            ?.map((p) => `${p.standard}: ${p.formatted_price}`)
            .join("; ")
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Taxa de Abate </template>
        {{
          Number(provider?.tax || 0)
            ?.toFixed(2)
            ?.replace(".", ",")
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> % Desconto </template>
        {{
          Number(provider?.deduction || 0)
            ?.toFixed(2)
            ?.replace(".", ",")
        }}
        %
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="start"><h4 class="mb-0">Resumo</h4></el-row>
    <el-row type="flex" justify="space-between">
      <h5 class="mb-0 text-grey">Peso no Romaneio:</h5>
      <h5 class="mb-0">
        {{ AnimalsWeight?.toFixed(2)?.replace(".", ",") }}
        kg.
      </h5>
    </el-row>
    <el-row type="flex" justify="space-between">
      <h5 class="mb-0 text-grey">Peso Descontado:</h5>
      <h5 class="mb-0">
        {{ DeductedAnimalWeight?.toFixed(2).replace(".", ",") }}
        kg.
      </h5>
    </el-row>

    <el-divider></el-divider>

    <el-row type="flex" justify="space-between">
      <h5 class="mb-0 text-grey">Valor do Romaneio com Peso Total:</h5>
      <h5 class="mb-0">
        {{
          currencyFormatter.format(
            AnimalPrices?.reduce((t, p) => (t += p), 0) || 0
          )
        }}
      </h5>
    </el-row>

    <el-row type="flex" justify="space-between">
      <h5 class="mb-0 text-grey">Desconto de Peso:</h5>
      <h5 class="mb-0 text-red">
        {{ currencyFormatter.format(DeductedPackingPrice || 0) }}
      </h5>
    </el-row>

    <el-row type="flex" justify="space-between">
      <h5 class="mb-0 text-grey">Taxa de abate:</h5>
      <h5 class="mb-0 text-red">
        {{ currencyFormatter.format(TotalTax || 0) }}
      </h5>
    </el-row>

    <el-row type="flex" justify="space-between">
      <h3 class="mb-0 text-grey">Valor a Pagar</h3>
      <h3 class="mb-0">
        {{ currencyFormatter.format(TotalDeductedPrice || 0) }}
      </h3>
    </el-row>

    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="warning" plain @click="uncheckPacking"
          >Devolver para conferência</el-button
        >
        <el-button
          type="text"
          class="button"
          @click="createPackingBill"
          :disabled="packing_?.status === 'completed'"
          >Finalizar romaneio</el-button
        >
      </el-row>
    </template>
    <bill-modal
      :bill="bill"
      :showModal="showBillModal"
      @close-modal="showBillModal = false"
      @should-update="finalizePacking"
      @fetch-firms="firms = $event"
      @fetch-costs="costs = $event"
    ></bill-modal>
  </el-dialog>
</template>

<script>
import { ElNotification } from "element-plus";
import BillModal from "./BillModal.vue";
import SystemService from "../../services/system";
import { notifyError, notifySuccess } from "../../utils/notifiers";

export default {
  props: ["packing", "showModal", "animal"],
  components: { BillModal },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      packing_: null,
      provider: null,
      showBillModal: false,
      bill: null,
      firms: [],
      costs: [],
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
    };
  },
  watch: {
    packing(v) {
      if (v) {
        this.packing_ = { ...v };
        if (v?.provider?.uid != this?.provider?.uid) {
          this.provider = v.provider;
          this.fetchProvider(v.provider.uid);
        }
      }
    },
    showModal(v) {
      if (v) {
        this.fetchCarcasses();
      }
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    AnimalsWeight() {
      return (
        this.packing_?.animals?.reduce(
          (t, e) => (t += Number(e.weight) + Number(e.weight_)),
          0
        ) || 0
      );
    },
    AnimalPrices() {
      return this?.packing_?.animals?.map(
        (a) =>
          (Number(a.weight || 0) + Number(a.weight_ || 0)) *
          Number(a.price || 0)
      );
    },
    TotalDeductedPrice() {
      return (
        this.AnimalPrices?.reduce((t, e) => (t += e), 0) -
        this.DeductedPackingPrice -
        this.TotalTax
      );
    },
    TotalTax() {
      return (
        Number(this?.packing_?.provider?.tax || 0) *
          Number(this?.packing_?.total) || 0
      );
    },
    DeductedAnimalWeight() {
      return (
        Number(this.AnimalsWeight) -
        Number(this.AnimalsWeight) *
          (Number(this.provider?.deduction || 0) / 100)
      );
    },
    DeductedPackingPrice() {
      return Number(this.packing_?.provider?.deduction)
        ? this.AnimalPrices.reduce((t, e) => (t += e), 0) *
            (Number(this.packing_.provider.deduction) / 100)
        : 0;
    },
    CarcassesWeight() {
      return (
        this.packing_?.animals
          ?.map((a) => a.carcasses)
          .flat()
          ?.reduce((t, l) => (t += Number(l?.weight)), 0) || 0
      );
    },
  },
  methods: {
    async finalizePacking(bill) {
      const shouldPrintBill = await this.$confirm(
        "Deseja imprimir a conta?",
        "Romaneio finalizado com sucesso",
        {
          confirmButtonText: "Imprimir",
          cancelButtonText: "Não",
          type: "success",
        }
      )
        .then(() => true)
        .catch(() => false);

      bill.firm = this.firms?.find((f) => f.uid === bill.firm_id);
      bill.cost = this.costs?.find((c) => c.uid === bill.cost_id);

      if (shouldPrintBill) this.printBill(bill);

      this.packing_.status = "completed";
      this.packing_.accepted_by = this.$store?.state?.user?.uid;
      this.packing_.bill_id = bill.uid;

      this.updatePacking();
      this.$emit("should-update");
      this.$emit("close-modal");
    },
    createPackingBill() {
      this.bill = {
        amount: this.TotalDeductedPrice,
        bought_at: this.packing_?.created_at,
        partner: this.packing_?.provider,
        entity: this.packing_,
        invoice_id: this.packing_.code,
        entity_name: "Romaneios",
      };
      this.showBillModal = true;
    },
    uncheckPacking() {
      this.$confirm(
        "Você precisará checar todos os animais novamente",
        "Devolver romaneio para conferência",
        {
          confirmButtonText: "Devolver",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          this.packing_.status = "checking";
          this.updatePacking();
        })
        .catch(() => {});
    },
    async printBill(bill) {
      const [payment] = bill?.payments || [];

      if (payment) {
        if (this.$store.state.user.printer?.uid) {
          const { job } = await SystemService()
            .Printers(this.$store.state.user.printer.uid)
            .Job()
            .create({
              layout: "bills",
              print_content: JSON.stringify({
                ...payment,
                bill,
              }),
            });

          if (job) notifySuccess("Impressão enviada para a impressora");
          else
            notifyError(
              "Não foi possível enviar a impressão para a impressora"
            );
        } else {
          try {
            await SystemService()
              .Print()
              .Bill(
                new URLSearchParams({
                  codConta: bill?.ref,
                  empresa: bill?.firm?.name,
                  fornecedor: bill?.partner?.name,
                  centroCusto: `${
                    bill?.cost?.category ? bill?.cost?.category : ""
                  }${
                    bill?.cost?.subcategory
                      ? " -> " + bill?.cost?.subcategory
                      : ""
                  }${bill?.cost?.name ? " -> " + bill?.cost?.name : ""}${
                    bill?.cost?.entity ? " -> " + bill?.cost?.entity : ""
                  }`,
                  dataAquisicao: new Date(bill?.bought_at).toLocaleDateString(),
                  dataVencimento: new Date(
                    bill?.expires_at
                  ).toLocaleDateString(),
                  dataVencimentoSobra: "-",
                  valor: this.currencyFormatter.format(Number(bill?.amount)),
                  descricao: "-",
                  numeroParcela: 1,
                  qtdParcela: bill?.quota || "1",
                })
              );

            notifySuccess("Impressão enviada para a impressora");
          } catch (e) {
            notifyError(
              "Ocorreu um erro ao enviar a impressão para a impressora"
            );
          }
        }
      }
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    isCarcassSelected(carcass) {
      if (carcass?.uid)
        return (
          this.animal?.carcasses?.some((l) => l?.uid === carcass?.uid) || false
        );
      return false;
    },
    fetchProvider(uid) {
      const url = new URL(`${this.$store.state.apiUrl}partners/${uid}`);
      if (uid)
        fetch(url, {
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            this.isLoadingSearch = false;
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then((e) => (this.provider = e));
    },
    updatePacking() {
      fetch(`${this.$store.state.apiUrl}packings/${this.packing_.uid} `, {
        credentials: "include",
        method: `PUT`,
        body: JSON.stringify({ ...this.packing_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.$emit("close-modal");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchCarcasses() {
      this.isLoadingCarcasses = true;
      const url = new URL(`${this.$store.state.apiUrl}carcasses`);
      url.search = new URLSearchParams({
        provider_id: this?.packing?.provider?.uid || "",
        packing_id: this?.packing?.uid,
        animal_id: this?.animal?.uid,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.carcasses = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoadingCarcasses = false));
    },
  },
  name: "PackingListCheckModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.warning-button {
  color: coral;
}
.text-grey {
  color: grey;
}
.text-red {
  color: crimson;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>
