<template>
  <el-dialog
    title="Produtos da Nota"
    lock-scroll
    append-to-body
    v-model="shouldShow"
    width="95%"
  >
    <el-row type="flex" justify="start"
      ><h4 class="mb-0">Dados da nota</h4></el-row
    >
    <el-descriptions class="margin-top" :column="4" size="small" border>
      <el-descriptions-item>
        <template #label> Fornecedor </template>
        {{ purchase?.provider?.name || "desconhecido" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Aferido </template>
        {{ sumValuesByKey(purchase.entries, "weight") }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Descrito </template>
        {{ sumValuesByKey(purchase.entries, "desc_weight") }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Produtos </template>
        {{ purchase.total }}
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="start"><h4 class="mb-0">Produtos</h4></el-row>

    <el-row type="flex" justify="end" class="mb-4">
      <el-popover
        :width="500"
        :visible="showLinkProductEntry"
        :disabled="!canLinkMoreEntries()"
      >
        <template #reference>
          <el-button
            @click="() => (showLinkProductEntry = true)"
            :disabled="!canLinkMoreEntries()"
            >Vincular produtos</el-button
          >
        </template>
        <div>
          <h4>Selecione uma entrada de produto para vincular à nota</h4>
          <el-divider></el-divider>
          <el-select
            popper-append-to-body
            style="width: 100%"
            v-model="selectedProductEntry"
            clearable
            size="large"
            value-key="uid"
            popper-class="ProductEntriesSelect"
            @visible-change="fetchProductEntries"
            :loading="!entries"
          >
            <el-option
              v-for="item in CompiledEntries"
              :key="item.uid"
              :label="item.name"
              :value="item"
            >
              <div style="display: flex; flex-direction: column">
                <span style="float: left">{{ item.name }}</span>
                <el-row type="flex" justify="space-between">
                  <span
                    style="
                      float: left;
                      color: var(--el-text-color-secondary);
                      font-size: 10px;
                    "
                    >{{ item.provider?.name }}</span
                  >
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 10px;
                    "
                    >{{
                      numberFormatter.format(Number(item.weight) || 0)
                    }}
                    kg.</span
                  >
                </el-row>
              </div>
            </el-option>
          </el-select>
          <el-divider></el-divider>
          <el-row type="flex" justify="space-between">
            <el-button
              type="warning"
              plain
              @click="() => (showLinkProductEntry = false)"
              >Cancelar</el-button
            >
            <el-button type="primary" @click="linkEntryToPurchase"
              >Adicionar</el-button
            >
          </el-row>
        </div>
      </el-popover>
    </el-row>

    <el-table
      :data="ProductEntries"
      cell-class-name="p-1"
      :cell-style="() => 'text-align:center;'"
      v-loading="isLoadingProducts"
      empty-text="Nenhuma entrada foi cadastrada na nota"
    >
      <el-table-column label="produto" prop="product.name"> </el-table-column>
      <el-table-column label="peso descrito">
        <template #default="r">
          <base-input
            :disabled="purchase?.status === 'completed'"
            type="money"
            :minimumFractionDigits="3"
            v-model="r.row.desc_weight"
            v-on:keyup.enter="updateEntry(r.row)"
          >
          </base-input>
        </template>
      </el-table-column>
      <el-table-column label="preço">
        <template #default="r">
          <base-input
            :disabled="purchase?.status === 'completed'"
            type="money"
            v-model="r.row.price_per_kg"
            v-on:keyup.enter="updateEntry(r.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column
        label="peso aferido"
        :formatter="
          (r) => `${numberFormatter.format(Number(r.weight) || 0)} kg.`
        "
      ></el-table-column>
      <el-table-column label="ações">
        <template #default="c">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                title="Remover produto da nota?"
                :disabled="purchase?.status === 'completed'"
                confirm-button-text="Sim"
                cancel-button-text="Não"
                @confirm="unlinkProductEntryFromPurchase(c.row)"
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    :disabled="purchase?.status === 'completed'"
                  >
                    <i class="el-icon-delete"></i
                  ></el-button>
                </template>
              </el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <el-row
        :justify="
          ['checking', 'analysis'].includes(purchase_?.status)
            ? 'space-between'
            : 'end'
        "
        type="flex"
      >
        <el-button
          v-if="['checking', 'analysis'].includes(purchase_?.status)"
          type="warning"
          plain
          @click="
            () =>
              updatePurchase({ ...purchase_, status: 'created' }) |
              $emit('close-modal')
          "
          >Devolver para montagem</el-button
        >
        <el-popconfirm
          v-if="purchase_?.status === 'checking'"
          @confirm="checkPurchase"
          title="Tem certeza que deseja finalizar a conferência?"
        >
          <template #reference>
            <el-button type="primary">Finalizar conferência</el-button>
          </template>
        </el-popconfirm>
        <el-button type="text" class="button" @click="shouldShow = false" v-else
          >Fechar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: [
    "purchase",
    "showModal",
    "providers",
    "showAnimalPriceUpdate",
    "showCheckedBy",
  ],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      purchase_: null,
      showPackingListCheckModal: false,
      carcasses: null,
      entries: null,
      selectedProductEntry: null,
      provider: null,
      isLoadingProducts: false,
      showLinkProductEntry: false,
      isLoadingSave: false,
      isLoadingSearch: true,
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
      }),
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
      intervalPooling: null,
    };
  },
  watch: {
    purchase(v) {
      this.purchase_ = { ...v };

      if (v?.provider?.uid && v.provider.uid != this?.provider?.uid) {
        this.provider = v.provider;
        this.fetchProvider(v?.provider?.uid);
      }
    },
    showModal(v) {
      if (v) this.fetchProducts();
    },
  },
  computed: {
    CompiledEntries() {
      return (
        this.entries?.data?.map((e) => ({
          ...e,
          name: `${e.product?.name} ${e.mounted?.cutting || ""} ${
            e.mounted?.composition || ""
          }`,
        })) || []
      );
    },
    ProductEntries() {
      return this.purchase?.entries || [];
    },
    isNew() {
      return !this.purchase;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    isAnyDataBlank() {
      return (
        this.purchase?.entries?.some((e) => {
          return !e.weight || !e.price_per_kg;
        }) || false
      );
    },
    hasAnyWeightLoss(acceptedLosses = 1) {
      return (
        this.purchase?.entries?.some((e) => {
          return Number(e.desc_weight) - Number(e.weight) > acceptedLosses;
        }) || false
      );
    },
    isProductsMissing() {
      return Number(this.purchase?.total) != this.purchase?.entries?.length;
    },
    hasProblemsInPurchase() {
      return (
        this.isAnyDataBlank() ||
        this.hasAnyWeightLoss() ||
        this.isProductsMissing()
      );
    },
    unlinkProductEntryFromPurchase(entry) {
      if (entry)
        this.updateEntry({
          uid: entry.uid,
          purchase_id: "",
        });
    },
    checkPurchase() {
      if (this.hasProblemsInPurchase()) {
        ElNotification({
          title: "Problemas na nota",
          message: "A nota foi enviada para análise",
          type: "warning",
        });
      }

      this.updatePurchase({
        uid: this.purchase.uid,
        status: this.hasProblemsInPurchase() ? "analysis" : "checked",
        checked_by: this.$store?.state?.user?.uid,
      });

      this.$emit("close-modal");
    },
    canLinkMoreEntries() {
      return this.purchase?.entries?.length < this.purchase?.total;
    },
    sumValuesByKey(values, key) {
      return this.numberFormatter.format(
        Number(values?.reduce((t, v) => (t += Number(v[key]) || 0), 0)) || 0
      );
    },
    fetchProductEntries(isOpen) {
      if (!isOpen) return;
      this.entries = null;
      const url = new URL(`${this.$store.state.apiUrl}entries`);
      url.search = new URLSearchParams({
        pagination: 1,
        status: "not included",
        firm_id: this.purchase.firm?.uid,
        provider_id: this.purchase.provider?.uid,
        limit: 2000,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.json();
        })
        .then((json) => (this.entries = json))
        .catch(() => {});
    },
    linkEntryToPurchase() {
      if (this.selectedProductEntry) {
        this.updateEntry({
          uid: this.selectedProductEntry.uid,
          purchase_id: this.purchase.uid,
        });
        this.showLinkProductEntry = false;
        this.selectedProductEntry = null;
      }
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    updatePurchase(purchase) {
      fetch(`${this.$store.state.apiUrl}purchases/${purchase.uid} `, {
        credentials: "include",
        method: `PUT`,
        body: JSON.stringify({ ...purchase }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.fetchProducts();
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({
        key: "defined_standards",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.standards = e));
    },
    fetchProvider(uid) {
      const url = new URL(`${this.$store.state.apiUrl}partners/${uid}`);
      if (uid)
        fetch(url, {
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            this.isLoadingSearch = false;
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then((e) => (this.provider = e));
    },
    updateEntry(entry) {
      return fetch(`${this.$store.state.apiUrl}entries/${entry.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          ...entry,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            this.$emit("should-update");
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchProducts() {
      this.isLoadingProducts = true;
      const url = new URL(`${this.$store.state.apiUrl}carcasses`);
      url.search = new URLSearchParams({
        provider_id: this?.packing?.provider?.uid || "",
        purchase_id: this?.packing?.uid,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.carcasses = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoadingProducts = false));
    },
  },
  name: "CarcassModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.centralized-cell {
  text-align: center !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.text-grey {
  color: grey;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-4 {
  margin-bottom: 16px;
}
.p-1 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.ProductEntriesSelect .el-select-dropdown__item {
  line-height: unset;
  height: 45px;
}
</style>