<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Conferência das notas</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="24">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-col :md="12" align="center">
                  <el-row type="flex" justify="end" style="margin-top: 5px">
                    <el-dropdown
                      @command="(c) => (purchaseStatus = c) | fetchPurchases()"
                      size="medium"
                      trigger="click"
                    >
                      <el-button type="primary" size="medium">
                        Filtrar por: {{ PurchaseStatus[purchaseStatus] || ""
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item command="checking">
                            <li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: purchaseStatus === 'checking',
                              }"
                            >
                              Conferência
                            </li>
                          </el-dropdown-item>
                          <el-dropdown-item command="checked"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: purchaseStatus === 'checked',
                              }"
                            >
                              Conferidos
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="analysis"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: purchaseStatus === 'analysis',
                              }"
                            >
                              Análise
                            </li></el-dropdown-item
                          >
                          <el-dropdown-item command="completed"
                            ><li
                              :class="{
                                'el-dropdown-menu__item': true,
                                'no-padding': true,
                                active: purchaseStatus === 'completed',
                              }"
                            >
                              Finalizados
                            </li></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-row>
                </el-col>
                <el-button
                  type="primary"
                  v-if="false"
                  icon="el-icon-s-operation"
                  class="mb-0"
                  size="medium"
                  @click="openFilterPackingModal()"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Purchases?.data"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="nf" label="Nº"> </el-table-column>
      <el-table-column
        prop="bought_at"
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor" prop="provider.name">
        <template #default="c" v-if="purchaseStatus != 'completed'">
          <el-select
            remote
            :modelValue="c?.row?.provider?.uid"
            @update:modelValue="
              (v) =>
                purchaseStatus != 'completed'
                  ? updateProviderSelect(c.row, v)
                  : ''
            "
            size="medium"
            value-key="uid"
            @select="
              () =>
                purchaseStatus != 'completed'
                  ? updatePurchase({
                      uid: c.row.uid,
                      provider: { uid: c.row.provider.uid },
                    })
                  : ''
            "
            :loading="!partners"
          >
            <el-option
              v-for="item in Partners"
              :key="item.uid"
              :label="`${item.name?.slice(0, 15)}${
                item?.name?.length > 15 ? '...' : ''
              }`"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="peso aferido">
        <template #default="p">
          {{ sumValuesByKey(p.row.entries, "weight") }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="peso declarado">
        <template #default="p">
          {{ sumValuesByKey(p.row.entries, "desc_weight") }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="preço total">
        <template #default="p">
          {{
            `${currencyFormatter.format(
              p?.row?.entries?.reduce(
                (t, e) =>
                  (t +=
                    Number(e?.desc_weight || 0) * Number(e?.price_per_kg || 0)),
                0
              )
            )}`
          }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="150">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                v-if="
                  ['analysis', 'checked', 'completed'].includes(purchaseStatus)
                "
                circle
                @click="openCompletePurchaseModal(emp.row)"
              >
                <i class="el-icon-finished"></i
              ></el-button>
              <el-button
                v-if="
                  ['checking', 'analysis', 'completed'].includes(purchaseStatus)
                "
                onlyIcon
                type="primary"
                circle
                @click="openPurchaseProductsModal(emp.row)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-button
                v-if="['completed'].includes(purchaseStatus)"
                onlyIcon
                type="primary"
                circle
                :disabled="!emp.row.bill"
                @click="printBill(emp.row.bill)"
              >
                <i class="el-icon-printer"></i
              ></el-button>
              <el-popconfirm
                @confirm="deletePurchase(emp.row)"
                title="
              Remover romaneio?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Purchases?.lastPage"
    >
    </el-pagination>
    <purchases-products-modal
      :showModal="showPurchasesProductsModal"
      :purchase="purchase"
      @close-modal="showPurchasesProductsModal = false"
      @should-update="fetchPurchases"
    ></purchases-products-modal>
    <complete-purchase-modal
      :showModal="showCompletePurchaseModal"
      :purchase="purchase"
      @close-modal="showCompletePurchaseModal = false"
      @should-update="fetchPurchases"
    >
    </complete-purchase-modal>
  </el-card>
</template>

<script>
import PurchasesProductsModal from "./modals/PurchasesProductsModal.vue";
import CompletePurchaseModal from "./modals/CompletePurchaseModal.vue";
import { ElNotification } from "element-plus";
import SystemService from "../services/system";
import { notifyError, notifySuccess } from "../utils/notifiers";

export default {
  name: "PurchaseCheckPage",
  components: {
    PurchasesProductsModal,
    CompletePurchaseModal,
  },
  data: () => ({
    hasError: false,
    isLoading: false,
    showBillModal: false,
    purchases: [],
    currentPage: 1,
    showPurchasesProductsModal: false,
    showCompletePurchaseModal: false,
    purchaseStatus: "checking",
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
    partners: null,
    purchase: null,
    filterOptions: {},
    showPackingListModal: false,
  }),
  watch: {
    purchases() {
      this.updateSelectedPurchase();
    },
    currentPage() {
      this.fetchPurchases();
    },
    purchaseStatus() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.fetchPurchases();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
    Purchases() {
      return this.purchases || [];
    },
    PurchaseStatus() {
      return {
        checking: "Conferência",
        checked: "Conferidos",
        analysis: "Análise",
        completed: "Finalizados",
      };
    },
  },
  methods: {
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.resetPagination();
      this.fetchPurchases();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    openCompletePurchaseModal(purchase) {
      this.purchase = purchase;
      this.showCompletePurchaseModal = true;
    },
    async printBill(bill) {
      const payment = bill.payments[0];
      if (payment) {
        if (this.$store.state.user.printer?.uid) {
          const { job } = await SystemService()
            .Printers(this.$store.state.user.printer.uid)
            .Job()
            .create({
              layout: "bills",
              print_content: JSON.stringify({
                ...payment,
                bill,
              }),
            });

          if (job) notifySuccess("Impressão enviada para a impressora");
          else
            notifyError(
              "Não foi possível enviar a impressão para a impressora"
            );
        } else {
          try {
            await SystemService()
              .Print()
              .Bill(
                new URLSearchParams({
                  codConta: payment?.ref,
                  empresa: bill?.firm?.name,
                  fornecedor: bill?.partner?.name,
                  centroCusto: `${
                    bill?.cost?.category ? bill?.cost?.category : ""
                  }${
                    bill?.cost?.subcategory
                      ? " -> " + bill?.cost?.subcategory
                      : ""
                  }${bill?.cost?.name ? " -> " + bill?.cost?.name : ""}${
                    bill?.cost?.entity ? " -> " + bill?.cost?.entity : ""
                  }`,
                  dataAquisicao: new Date(bill?.bought_at).toLocaleDateString(),
                  dataVencimento: new Date(
                    payment?.expires_at
                  ).toLocaleDateString(),
                  dataVencimentoSobra: "-",
                  valor: this.currencyFormatter.format(Number(payment?.amount)),
                  descricao: bill?.obs || "-",
                  numeroParcela: 1,
                  qtdParcela: bill?.quota || "1",
                })
              );

            notifySuccess("Impressão enviada para a impressora");
          } catch (e) {
            notifyError(
              "Ocorreu um erro ao enviar a impressão para a impressora"
            );
          }
        }
      }
    },
    updateProviderSelect(packing, data) {
      packing.provider_id = data;
      this.updatePurchase(packing);
    },
    openBillsModal() {
      this.showBillModal = true;
    },
    sumValuesByKey(values, key) {
      return this.numberFormatter.format(
        Number(values?.reduce((t, v) => (t += Number(v[key]) || 0), 0)) || 0
      );
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    deletePurchase(purchase) {
      if (purchase)
        fetch(`${this.$store.state.apiUrl}purchases/${purchase.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.json();
          })
          .then(() => {
            this.fetchPurchases();
            this.isLoading = false;
            this.$notify({
              title: "Nota de compra removida com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover a nota de compra",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openPurchaseProductsModal(e) {
      (this.purchase = e), (this.showPurchasesProductsModal = true);
    },
    updatePurchase(purchase) {
      if (purchase)
        fetch(`${this.$store.state.apiUrl}purchases/${purchase.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...purchase }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchPurchases();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar o romaneio",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
    },
    updateSelectedPurchase() {
      if (this.purchase)
        this.purchase =
          this.purchases?.data?.find((p) => p.uid === this.purchase.uid) ||
          this.purchase;
    },
    fetchPurchases() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}purchases`);
      url.search = new URLSearchParams({
        pagination: this.currentPage,
        status: this.purchaseStatus,
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.purchases = json;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>
