<template>
  <div>
    <packing-list-check
      v-if="
        canAny(
          'UpdateAnimal',
          'UpdateCarcass',
          'UpdatePackingList',
          'AnalysePackingList',
          'CheckPackingList'
        )
      "
    ></packing-list-check>
    <purchase-check-page
      class="margin-top"
      v-if="canAny('UpdatePurchases', 'CreatePurchases', 'DeletePurchases')"
    ></purchase-check-page>
  </div>
</template>
<script>
import PackingListCheck from "./packing_list_check.vue";
import PurchaseCheckPage from "./purchases_check.vue";
export default {
  name: "ChecksPage",
  components: {
    PackingListCheck,
    PurchaseCheckPage,
  },
  computed: {
    can() {
      return this.$store.state.auth.can;
    },
  },
  methods: {
    canAny() {
      for (let a in arguments) if (this.can(arguments[a])) return true;

      return false;
    },
  },
};
</script>
<style scoped>
.mt-4 {
  margin-top: 1.4rem;
}
</style>