<template>
  <el-dialog
    v-model="shouldShow"
    title="Finalizar Nota de Entrada"
    append-to-body
  >
    <el-descriptions title="Resumo da nota" border>
      <el-descriptions-item label="Cliente">
        {{ purchase?.provider?.name }}
      </el-descriptions-item>
      <el-descriptions-item label="Nº da Nota">
        {{ purchase?.nf }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" justify="start"> <h4>Produtos</h4> </el-row>
    <el-table :data="purchase?.entries">
      <el-table-column label="nome" prop="product.name"></el-table-column>
      <el-table-column
        label="peso aferido"
        prop="weight"
        :formatter="(r) => numberFormatter.format(r.weight)"
      ></el-table-column>
      <el-table-column
        label="peso descrito"
        prop="weight"
        :formatter="(r) => numberFormatter.format(r.desc_weight)"
      ></el-table-column>
      <el-table-column
        label="preço"
        prop="weight"
        :formatter="(r) => currencyFormatter.format(r.price_per_kg)"
      ></el-table-column>
      <el-table-column
        label="total"
        prop="weight"
        :formatter="
          (r) =>
            currencyFormatter.format(
              Number(r.price_per_kg) * Number(r.desc_weight)
            )
        "
      ></el-table-column>
    </el-table>

    <el-row type="flex" justify="space-between">
      <h5 style="width: 80%; text-align: start">Total:</h5>
      <h4 style="width: 20%; text-align: center">
        {{ currencyFormatter.format(sumTotalPurchases()) }}
      </h4>
    </el-row>

    <template #footer>
      <el-row type="flex" justify="space-between">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
       <el-row type="flex">
        <el-button
          :v-if="purchase?.status !== 'checking'"
          type="warning"
          plain
          class="button"
          @click="setPurchaseAsChecking"
          >Devolver pra conferência</el-button
        >
        <el-button
          type="primary"
          :disabled="purchase?.status === 'completed'"
          :isLoading="isLoadingSave"
          class="button"
          @click="setPurchaseAsCompleted"
          >Finalizar nota</el-button
        >
       </el-row>
      </el-row>
    </template>
    <bill-modal
      :showModal="showBillModal"
      :bill="bill"
      @close-modal="showBillModal = false"
      @should-update="completePurchase"
      @fetch-firms="firms = $event"
      @fetch-costs="costs = $event"
    ></bill-modal>
  </el-dialog>
</template>
<script>
import BillModal from "./BillModal.vue";
import { ElNotification } from "element-plus";
import SystemService from "../../services/system";
import BillService from "../../services/bills";
import { notifyError, notifySuccess } from "../../utils/notifiers";
export default {
  name: "CompletePurchaseModal",
  props: ["showModal", "purchase"],
  data() {
    return {
      isLoadingSave: false,
      showBillModal: false,
      bill: null,
      firms: null,
      costs: null,
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
      }),
    };
  },
  emit: ["close-modal", "should-update"],
  components: { BillModal },
  methods: {
    sumTotalPurchases() {
      return this.purchase?.entries?.reduce(
        (t, v) => (t += Number(v.price_per_kg) * Number(v.desc_weight)),
        0
      );
    },
    setPurchaseAsCompleted() {
      this.openBillModal({
        firm: this.purchase.firm,
        amount: this.sumTotalPurchases(),
        bought_at: this.purchase.bought_at,
        partner: this.purchase?.provider,
        entity: this.purchase,
        expires_at: this.purchase.expires_at,
        invoice_id: this.purchase.nf,
        entity_name: "Compras",
      });
    },
    async deleteRelatedBill(bill){
      if(bill?.uid){
        await BillService(bill.uid).delete();
      }
    },
    setPurchaseAsChecking(){
      this.updatePurchase({uid: this.purchase.uid, status: 'checking'});

      if(this.purchase.bill)
        this.deleteRelatedBill(this.purchase.bill);

      this.$emit('should-update');
      this.$emit('close-modal');
    },
    async printBill(bill) {
      const [payment] = bill?.payments || [];
      if (payment) {
        if (this.$store.state.user.printer?.uid) {
          const { job } = await SystemService()
            .Printers(this.$store.state.user.printer.uid)
            .Job()
            .create({
              layout: "bills",
              print_content: JSON.stringify({
                ...payment,
                bill,
              }),
            });

          if (job) notifySuccess("Impressão enviada para a impressora");
          else
            notifyError(
              "Não foi possível enviar a impressão para a impressora"
            );
        } else {
          try {
            await SystemService()
              .Print()
              .Bill(
                new URLSearchParams({
                  codConta: bill?.ref,
                  empresa: bill?.firm?.name,
                  fornecedor: bill?.partner?.name,
                  centroCusto: `${
                    bill?.cost?.category ? bill?.cost?.category : ""
                  }${
                    bill?.cost?.subcategory
                      ? " -> " + bill?.cost?.subcategory
                      : ""
                  }${bill?.cost?.name ? " -> " + bill?.cost?.name : ""}${
                    bill?.cost?.entity ? " -> " + bill?.cost?.entity : ""
                  }`,
                  dataAquisicao: new Date(bill?.bought_at).toLocaleDateString(),
                  dataVencimento: new Date(
                    bill?.expires_at
                  ).toLocaleDateString(),
                  dataVencimentoSobra: "-",
                  valor: this.currencyFormatter.format(
                    new Number(bill?.amount)
                  ),
                  descricao: "-",
                  numeroParcela: 1,
                  qtdParcela: bill?.quota || "1",
                })
              );

            notifySuccess("Impressão enviada para a impressora");
          } catch (e) {
            notifyError(
              "Ocorreu um erro ao enviar a impressão para a impressora"
            );
          }
        }
      }
    },
    async completePurchase(bill) {
      const shouldPrintBill = await this.$confirm(
        "Deseja imprimir a conta?",
        "Romaneio finalizado com sucesso",
        {
          confirmButtonText: "Imprimir",
          cancelButtonText: "Não",
          type: "success",
        }
      )
        .then(() => true)
        .catch(() => false);

      const _ = this.purchase;

      _.status = "completed";
      _.bill_id = bill.uid;
      this.updatePurchase(_);

      bill.firm = this.firms?.find((f) => f.uid === bill.firm_id);
      bill.cost = this.costs?.find((c) => c.uid === bill.cost_id);

      if (shouldPrintBill) this.printBill(bill);

      this.closeModal();
    },
    closeModal() {
      this.shouldShow = false;
    },
    openBillModal(bill) {
      this.bill = bill;
      this.showBillModal = true;
    },
    updatePurchase(p) {
      fetch(`${this.$store.state.apiUrl}purchases/${p.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...p }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar a nota",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  computed: {
    shouldShow: {
      set() {
        this.$emit("close-modal", !this.showModal);
      },
      get() {
        return !!this.showModal;
      },
    },
  },
};
</script>
